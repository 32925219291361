:root {
  --colors__white: #fff;
  --colors__frost: #f6f6f6;
  --colors__dove: #e2e4e4;
  --colors__black: #1a1b2e;
  --colors__grey: #596267;
  --colors__azure: #28519d;
  --colors__verde: #188369;
  --colors__cerise: #d33867;
  --colors__success: #0eb400;
  --colors__error: #ff6161;
  --colors__plum: #5f2cbf;
  --colors__light-azure: #d5f1fe;
  --colors__light-verde: #d1f4ec;
  --colors__light-cerise: #fad8e3;
  --colors__light-plum: #e7d8f7;
  --color__body-copy-text: var(--colors__black);
  --NPTC-Gradient: linear-gradient(
    217deg,
    var(--colors__verde),
    var(--colors__azure),
    var(--colors__plum)
  );
}
