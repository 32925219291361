@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: visible;
    pointer-events: all;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(calc(-1 * 100%));
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-out-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(-1 * 100%));
  }
}

// ark ui looks for animations to delay adding it's hidden css class so we add a css animation that does nothing because we want the real css animation on a child component
@keyframes do-nothing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
