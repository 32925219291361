:root {
  --spacing-L: 80px;
  --spacing-M: 40px;
  --spacer__default-spacing: var(--spacing-M);
}

.spacer {
  padding-top: var(--spacer__padding-top, var(--spacer__padding-y, var(--spacer__default-spacing)));
  padding-bottom: var(
    --spacer__padding-bottom,
    var(--spacer__padding-y, var(--spacer__default-spacing))
  );
}

.spacer-L {
  padding-top: var(--spacer__padding-top, var(--spacer__padding-y, var(--spacer__default-spacing)));
  padding-bottom: var(
    --spacer__padding-bottom,
    var(--spacer__padding-y, var(--spacer__default-spacing))
  );

  @include media('>=tablet-landscape') {
    --spacer__default-spacing: var(--spacing-L);
  }
}
