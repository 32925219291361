:root {
  --container__padding-x: var(--spacing-compact);

  @include media('>=tablet-landscape') {
    --container__padding-x: var(--spacing);
  }
}

.container-10 {
  --container-max-width: var(
    --container__max-width,
    calc(#{rem(1158px)} + var(--container__padding-x) * 2)
  );

  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: var(--container-max-width);
  padding-left: var(--container__padding-x);
  padding-right: var(--container__padding-x);
}

.container-12 {
  --container-max-width: var(
    --container__max-width,
    calc(#{rem(1558px)} + var(--container__padding-x) * 2)
  );

  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: var(--container__max-width, calc(rem(1558px) + var(--container__padding-x) * 2));
  padding-left: var(--container__padding-x);
  padding-right: var(--container__padding-x);
}
